.tickets-container{
  display: flex;
  padding: 1%;
  padding-bottom: 0;
  // min-height: 0;
  // margin-bottom: 1%;
  flex: 1;

  

  & > .tickets-filters-container{
    flex: 2;
    display: flex;
    flex-direction: column;
    margin-right: 0.5vw;
    padding: 0.5%;
    & > .filters-holder {
      display: flex;
      flex: 1;
      flex-direction: column;
      & > .filter{
        margin-bottom: 1vh;
      }
      & > .apply-holder{
      }
    }
  }
  & > .tickets-table-container{
    flex: 8;
    & > .table-pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.5vh;
    }
  }
 
  & > .new-ticket-container{
    & .form-field {
      margin-bottom: 1vh;
    }
    & .div1{
      float: left;
      width: 48%;
      margin-right: 2%;
    }
    & .div2{
      float: left;
      width: 50%;
    }
  }
  & > .ticket-detail-container {
    flex:1;
    display: flex;
    flex-direction: column;
    min-width: 0;
    & > .status-container {
      padding: 1%;
      overflow: scroll;
      // min-height: 40vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 0.5vh;
      max-height: 65vh;
      & > .status-holder {
        display: flex;
        margin-bottom: 1vh;
        &.group{
          justify-content: space-between;
          align-items: center;
        }
        &.group > div {
          margin-right: 1vw;
        }
        & > .status {
          flex:1;
        }
      }
   
    }
    & > .follow-up-container {
      // padding: 1%;
      padding-bottom: 0;
      display:flex;
      flex-direction: column;
      overflow: scroll;
      // min-height: 25vh;
      margin-top: auto;
      max-height: 100%;
      & > .entries-container {
        display: flex;
        flex-direction: column;
        flex: 9;
        overflow-y: scroll;
        margin-bottom: 0.5vh;
        max-height: 50vh;
        & > .entry-holder {
          display: flex;
          flex-direction: column;
          margin-bottom: 1vh;
          & > .entry-header-holder {
            display: flex;
            padding:0.5%;
            flex-direction: column;
            & > .top-holder{
              display: flex;
              align-items: center;
              & > .date-holder {
                flex: 10;
                display: flex;
                justify-content: flex-end;
              }
              // & > .username-holder {
              //   flex:4;
              // }
            }
            & > .bottom-holder{
              & > .files-holder {
                overflow-x: hidden;
                min-width: 0;
                & > a {
                  color:white;
                }
              }
            }
            &.user > .top-holder{
              justify-content: flex-start;
              flex-direction: row-reverse;
              & > .avatar-holder {
                margin-left: 1vw;
              }
              & > .date-holder {
                justify-content: flex-start;
              }
            }
            &.user > .bottom-holder{
              display: flex;
              justify-content: flex-start;
              flex-direction: row-reverse;
            }
            &.admin > .top-holder{
              & > .avatar-holder {
                margin-right: 1vw;
              }
            }
          }
          & > .entry-content-holder {
            display: flex;
            flex-direction: column;
            padding: 1%;
            overflow-x: overlay;
            &.user{
              // align-items: flex-end;
              // margin-left: 1vw;
            }
          }
        }
      }
      & .new-entry-holder {
        display: flex;
        align-items: center;
        flex: 1;
        & > .content-holder{
          flex: 12;
          overflow:scroll;
          max-height: 40vh;
        }
        & > .submit-holder{
          flex: 1;
          display: flex;
          align-content: center;
          flex-direction: column;
          justify-content: space-around;
        }

       
      }
    }
  }
}

.adr-container{
    display: flex;
    width: 100%;
    // min-height: 0;
    height: 100%;
    & > .paper {
      width:100%;
      overflow-y: scroll;
    }
        & .horas {
          float: left;
          width: 50%;
          }
        & .montos {
          float: left;
          width: 50%;
        }
        & .fechas {
          float: left;
          width: 50%;
          }
        & .factura {
          float: left;
          width: 50%;
          }
        & .grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 5px;
            width:100%;
          }
        & .grid2 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 5px;
            width:100%;
          }
        & .cuadricula1{
          float: left;
          width: 49%;
          margin-right: 0.5%;
        }
        & .cuadricula2{
          float: left;
          width: 49%;
          margin-right: 0.5%;
        }

        & .div1{
          float: left;
          width: 18%;
          background-color:coral;
        }
        & .div2{
          float: left;
          width: 20.5%;
          background-color:darkcyan;
        }
        & .div3{
          float: left;
          width: 30%;
          background-color:coral;
        }
        & .div4{
          float: left;
          width: 20%;
          background-color: cadetblue;
        }
        & .stringfecha{
          float: left;
         
          background-color: rgb(126, 131, 131);
        }
        & .fecha{
          float: left;
          width: 25%;
          background-color: yellow;
        }
        & .paper2{
            width: 100%;
            margin-left:10% ;

        }
       
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5px;
    grid-auto-rows: minmax(5x, auto);
  }
  .grid3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    grid-auto-rows: minmax(5x, auto);
  }
  .text{
  width:100%;}

  .one {
    grid-column: 1;
    grid-row: 1;
  }.two {
    grid-column: 2;
    grid-row: 1;
  }.three {
    grid-column: 3;
    grid-row: 1;
  }.four {
    grid-column: 1;
    grid-row: 2;
  }.five {
    grid-column: 2;
    grid-row: 2;
  }.six {
    grid-column: 3;
    grid-row: 2;
  }
 .formac {
    width: 90%;
      }
.users-container{
  display: flex;
  padding: 1%;
  min-height: 0;
  flex: 1;
  & > .users-filters-container{
    flex: 2;
    display: flex;
    flex-direction: column;
    margin-right: 0.5vw;
    padding: 0.5%;
    & > .filters-holder {
      display: flex;
      flex: 1;
      flex-direction: column;
      & > .filter{
        margin-bottom: 1vh;
      }
      & > .apply-holder{
      }
    }
  }
  & > .users-table-container{
    flex: 8;
    & > .table-pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.5vh;
    }
  }
  & > .new-user-container{
    & .form-field {
      margin-bottom: 1vh;
    }
  }
}

.grid1 {
    display: inline-grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(3px, auto);
    grid-gap: 3px;
    width: 80%;
    //margin-left: 350px;
  
  }

  


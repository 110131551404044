.sessions-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  & > .session-form-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    & > .session-form {
    }
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,body,#root{
  height:100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  font-family: arial;
  & ::-webkit-scrollbar{
    width:5px;
    height:5px;
  }
  & ::-webkit-scrollbar-thumb {
    background: gray;
  }
  scrollbar-width:thin; //Mozilla
}
.app-container{
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  background-color: #f5f5f5;
}

.backdrop-content{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.maintenance-container {
  display: flex;
  flex: 1;
  align-items: center;
}

/*Draft JS Editor*/
.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  // background-color:lightgray;
  // padding: 1rem;
  // border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

.show-wrapper-class {
  width:100%;
  overflow: visible;
}
.show-editor-class {
  overflow: visible !important;
}
/*END Draft JS Editor*/


  .padreModule {

    //height: 150px;
    /*IMPORTANTE*/
    //display: flex;
    //justify-content: center;
    //align-items: center;
    //background-color: green;
    float: left;
    width: 840px;
  }

  .hijoModule {

    width: 90px;
    float: left;
  }


  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    width:40%;
   }

  .grid-item {
   // border: 1px solid black;
  }

  .botonactualizar {
    width: 20%;
    margin-left: 100px;
   }

   .activity-container{
    display: flex;
   width: 100%;
    min-height: 01;
   // flex: 1;
    & > .activity-tabs-container{
      flex: 1;
    }
  }

.activity-skeleton-detail-wrapper {
  padding:1vw;
  & .activity-skeleton-detail-row {
    margin-bottom: 1vh;
    height: 10vh;
  }
}
.asignados{
  float: left;
  width: 84%;
  margin-left: 0.5em;
}
.icono{
  float: left;
}

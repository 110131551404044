.base-container{
  display: inline-flex;
  padding: 1%;
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
/*   margin-top: 50px;
  padding: 10px;
  margin: 10px; */
  max-width: 1500px;
  flex: 1;
  & > .base-filters-container{
    flex: 2;
    display: flex;
    flex-direction: column;
    margin-right: 0.5vw;
    padding-top: 15px;
    padding-bottom: 15px;
    min-width: 15vw;
    & > .filters-holder {
      display: flex;
      flex: 1;
      flex-direction: column;
      & > .filter{
        margin-bottom: 1vh;
      }
      & > .apply-holder{
      }
    }
  }
  & > .base-table-container{
    flex: 8;
    & > .table-pagination {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.5vh;
    }
  }
  & > .new-base-container{
    & .form-field {
      margin-bottom: 1vh;
    }
  }
}

.releases-container{
  display: flex;
  flex-direction: column;
  padding: 1%;
  min-height: 0;
  flex: 1;
  & > .releases-title {
    align-self: center;
  }
}

.base-filters-container{
  flex: 2;
  display: flex;
  flex-direction: column;
  margin-right: 0.5vw;
  padding-top: 0px;
  padding-bottom: 15px;
  & > .filters-holder {
    display: flex;
    flex: 1;
    flex-direction: column;
    & > .filter{
      margin-bottom: 1vh;
    }
    & > .apply-holder{
    }
  }
}

.priority-container {
  display: flex;
  align-items: center;
}

.priority-holder {
  display: flex;
  align-items: center;
}
.order-holder {
  display: flex;
  align-items: center;
  float: right;
  height: 15px;
}
